<template>
  <div>
    <Form class="practice-form" ref="practiceForm" :model="practiceForm" :rules="practiceRuleValidate" :label-width="80">
      <FormItem label="章节" v-if="$route.query.isChapter">
        <Input disabled :value="$route.query.chapter.chaName" />
      </FormItem>
      <FormItem label="课程小节" v-else>
        <!--        <Cascader v-model="practiceForm.sectionId" :data="chapters" placeholder="请选择课程小节" trigger="hover"-->
        <!--                  filterable/>-->
        <Input disabled :value="$route.query.chapter.chaName + '/' + $route.query.chapter.secName" />
      </FormItem>
      <FormItem label="练习名称" prop="name">
        <Input v-model="practiceForm.name" maxlength="80" show-word-limit placeholder="请输入练习名称" />
        <div class="form-tip" v-if="!practiceRuleValidate.name.error">
          不超过 80 个字
        </div>
      </FormItem>
      <!--      <FormItem label="截止时间" prop="endTime" style="width: 500px">-->
      <!--        <DateTimePicker v-model="practiceForm.endTime"></DateTimePicker>-->
      <!--      </FormItem>-->
      <!--      <FormItem label="题库类型">-->
      <!--        <CheckboxGroup v-model="questionBankType">-->
      <!--          <Checkbox label="mine">企业题库</Checkbox>-->
      <!--          <Checkbox label="open">公开题库</Checkbox>-->
      <!--          <Checkbox label="collect">收藏题库</Checkbox>-->
      <!--        </CheckboxGroup>-->
      <!--      </FormItem>-->
      <FormItem label="选择试题">
        <Button class="question-select-btn" @click="choseQuestion()" size="small">选择试题</Button>
      </FormItem>
    </Form>
    <draggable class="list-group" tag="ul" v-model="practiceForm.question" v-bind="dragOptions" @start="drag = true" @end="drag = false">
      <transition-group type="transition" :name="!drag ? 'flip-list' : null" v-if="isShow === true || practiceForm.question.length > 0">
        <div>{{ practiceForm }}</div>
        <div v-for="(question, index) in practiceForm.question" :key="'question-' + index" class="question-image-size-restrict">
          <div class="title">
            <div class="question-name">
              <span>题型：{{ question.questionType | questionType }}</span>
              <span>困难度：{{ ytConstant.difficultType.getLabel(question.difficulty) }}</span>
            </div>
            <div class="question-opt">
              <span class="delete-btn" @click="handleDeleteQuestion(question, index)">删除</span>
            </div>
          </div>
          <div class="stem">
            <div>{{ index + 1 }}.</div>
            <BaseQuestion :question="question" :show-answer="true" />
          </div>
        </div>
      </transition-group>
    </draggable>

    <QuestionSelectModal ref="question" v-model="selectModalShow" :data-list="sections" @finish="finish" />
  </div>
</template>

<script>
import courseApi from '@api/course'
import QuestionSelectModal from '../../exam/paper/QuestionSelectModal'
import BaseQuestion from '../../../common/questions/BaseQuestion'
import draggable from 'vuedraggable'
import { questionType } from '@/util/questionType'
import { difficulty } from '@/util/difficulty'
import DateTimePicker from '@components/common/DateTimePicker'

export default {
  name: 'CommonPractice',
  components: {
    QuestionSelectModal,
    BaseQuestion,
    draggable,
    DateTimePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    courseId: {
      type: Number,
      required: true
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    const that = this

    return {
      isShow: false,
      chapters: [],
      courseName: '',
      questionBankType: ['mine'],
      questionBank: '',
      selectModalShow: false,
      drag: false,
      //为了复用 QuestionSelectModal 而构造的数据结构
      sections: [
        {
          questionVOS: []
        }
      ],
      practiceForm: this.value,
      practiceRuleValidate: {
        sectionId: {
          type: 'array',
          len: 2,
          required: true,
          message: '请选择课程小节（非章节）'
        },
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.practiceRuleValidate.name.error = true
              callback(new Error('请输入练习名称'))
            } else if (value.length > 80) {
              that.practiceRuleValidate.name.error = true
              callback(new Error('练习名称不超过 80 个字'))
            } else {
              that.practiceRuleValidate.name.error = false
              callback()
            }
          }
        }
      }
    }
  },
  created() {
    courseApi.getCourseDetail(this.courseId).then(res => {
      this.chapters = res.res.chapters.map(chapter => {
        const item = {}
        item.value = chapter.chapterId
        item.label = chapter.name
        item.children = chapter.sections.map(section => {
          const child = {}
          child.value = section.sectionId
          child.label = section.name
          return child
        })
        return item
      })
      this.courseName = res.res.name
    })
  },
  methods: {
    setSections() {
      let payload = {
        questionVOS: this.value.question
      }
      this.$set(this.sections, 0, payload)
    },
    choseQuestion() {
      // 避免在更新练习的时候选择试题清空已选题目
      if (this.value.id !== 0) {
        this.sections[0].questionVOS = this.value.question
      }
      this.selectModalShow = true
      this.$refs.question.fetchQuestionBanks()
    },
    finish(list, data) {
      this.isShow = data
    },
    handleDeleteQuestion(q, index) {
      this.sections[0].questionVOS.forEach((item, iIndex) => {
        if (item.id === q.id) {
          this.sections[0].questionVOS.splice(iIndex, 1)
        }
      })
    },
    validate(callback) {
      return this.$refs.practiceForm.validate(callback)
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    sections: {
      deep: true,
      handler: function(sections) {
        this.practiceForm.question = sections[0].questionVOS
      }
    },
    practiceForm(practiceForm) {
      this.$emit('input', practiceForm)
    },
    value(val) {
      this.practiceForm = val
      this.sections[0].questionVOS = val.question
    }
  },
  filters: {
    questionType(type) {
      return questionType[type].label
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables.less';

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #e4e9ef;
  font-size: 16px;
  color: @font-color-content;

  .question-name {
    font-size: 12px;

    span + span {
      margin-left: 10px;
      padding: 0 10px;
      border-left: 1px solid @font-color-base;
    }
  }

  .question-opt {
    font-size: 12px;

    .delete-btn {
      cursor: pointer;
      color: @primary-color;
      transition: color @default-transition;

      &:hover {
        color: @primary-hover-color;
      }

      &:active,
      &:focus {
        color: @primary-active-color;
      }
    }

    .move {
      cursor: pointer;
      color: @primary-color;
      transition: color @default-transition;
      margin-left: 20px;

      &:hover {
        color: @primary-hover-color;
      }

      &:active,
      &:focus {
        color: @primary-active-color;
      }
    }
  }
}

.practice-form {
  min-width: 300px;
  width: 40%;
  padding: 20px;

  .question-select-btn {
    font-size: 12px;
  }
}

.stem {
  padding: 10px 20px;
  display: flex;
}
</style>
